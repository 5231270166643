import servicePlanningSapForm from '@/views/geocycle/sap/service_planning_saps/servicePlanningSapTable';
<template>
  <a-table
    :dataSource="tableObject.data"
    :columns="columns"
    :loading="tableObject.loading"
    :row-key="record => record.manifest"
    :pagination="tableObject.pagination"
    @change="refreshResults"
    bordered
    size="small"
  >
    <!--    :scroll="{ x: 1300 }"-->
    <div slot="filterDropdown"
         slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
         class='custom-filter-dropdown'>
      <a-input
        v-ant-ref="c => searchInput = c"
        :placeholder="`Buscar por ${column.title}`"
        :value="selectedKeys[0]"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm)"
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type='primary'
        @click="() => handleSearch(selectedKeys, confirm)"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
      >Buscar
      </a-button>
      <a-button
        @click="() => handleReset(clearFilters)"
        size="small"
        style="width: 90px"
      >Limpiar
      </a-button>
    </div>
    <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
    <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i"
                    class="highlight">{{ fragment }}</mark>
              <template v-else>{{ fragment }}</template>
          </template>
        </span>
      <template v-else>{{ text }}</template>
    </template>
    <!-- Columnas Personalizadas -->
    <template slot="description" slot-scope="record">
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Planta:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>
            {{ record.plant_code }} - {{ record.plant_name }}
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>No. de Programación:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.sap_programming_code }}</small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>No. de Transporte:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.sap_transport_code }}</small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>No. de Contabilización:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.sap_accounting_code }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>No. de Factura:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.sap_billing_code }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small v-if="!isForPurchase"><strong>Contrato:</strong></small>
          <small v-else><strong>Pedido:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.sap_contract }}</small>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row">
        <div class="col-6 text-right">
          <small v-if="!isForPurchase"><strong>Clave Cliente SAP:</strong></small>
          <small v-else><strong>Clave Proveedor SAP:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.client_code }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small v-if="!isForPurchase"><strong>Cliente:</strong></small>
          <small v-else><strong>Proveedor:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.client_name }} </small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Planta Cliente:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.client_plant }} </small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Recolector:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.collector }} </small>
        </div>
      </div>
    </template>

    <template slot="details" slot-scope="record">
      <!-- <div class="row">
        <div class="col-6 text-right">
          <small><strong>Tipo de Contabilización:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.client_accounting_type_name }}</small>
        </div>
      </div> -->
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Manifiesto:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.manifest }}</small>
        </div>
      </div>
      <hr class="bg-primary">
      <!-- <div class="row">
        <div class="col-6 text-right">
          <small><strong>Cantidad Programada:</strong></small>
        </div>
        <div v-for="material in record.servicePlannings.servicePlanningsMaterials" :key="material.id">
          {{ material.currents.name }} - {{ material.container_capacity * material.container_quantity }} TON
        </div>
        <div class="col-6 text-left">
          <small>{{ record.sapDataMaterials.containers_manifest }}</small>
        </div>
      </div> -->
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Ticket de Báscula:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.weight_registry_ticket }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Pesaje Calculado:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ parseFloat(record.weight).toFixed(4) }} - TON</small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Pesaje Báscula:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ parseFloat(record.weight_total).toFixed(4) }} - TON</small>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Estatus:</strong></small>
        </div>
        <div class="col-6 text-left">
          <span v-show="record.status_sap_data === 'Pendiente'" class="text-danger">
            <small><strong>{{ record.status_sap_data }}</strong></small>
          </span>
          <span v-show="record.status_sap_data === 'Sin Envío a SAP'" class="text-danger">
            <small><strong>{{ record.status_sap_data }}</strong></small>
          </span>
          <span v-show="record.status_sap_data === 'En Batch'" class="text-warning">
            <small><strong>{{ record.status_sap_data }}</strong></small>
          </span>
          <span
            v-show="record.status_sap_data === 'Programación del Pedido' || record.status_sap_data === 'Contabilización del Pedido' || record.status_sap_data === 'Creación del Transporte' || record.status_sap_data === 'Especificación del Transporte'"
            class="text-blue-light">
            <small><strong>{{ record.status_sap_data }}</strong></small>
          </span>
          <span v-show="record.status_sap_data === 'Facturado'" class="text-success">
            <small><strong>{{ record.status_sap_data }}</strong></small>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Servicio:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small><strong>{{ record.service_planning }}</strong></small>
        </div>
      </div>
    </template>

    <template slot="services" slot-scope="record">
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Fecha / Hora de entrada:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>
            {{ record.servicePlannings.servicePlanningsPeriods.coming_dated_at }} / {{ record.servicePlannings.servicePlanningsPeriods.coming_hour }}
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Fecha / Hora de descarga:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>
            {{ record.servicePlannings.servicePlanningsPeriods.discharge_dated_at }} / {{ record.servicePlannings.servicePlanningsPeriods.discharge_hour }}
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Fecha / Hora de salida:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.servicePlannings.servicePlanningsPeriods.departure_dated_at }} / {{ record.servicePlannings.servicePlanningsPeriods.departure_hour }}</small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Tiempo de Descarga:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ moment.utc(moment(record.servicePlannings.servicePlanningsPeriods.departure_dated_at+' '+record.servicePlannings.servicePlanningsPeriods.departure_hour,"YYYY-MM-DD HH:mm:ss").diff(moment(record.servicePlannings.servicePlanningsPeriods.coming_dated_at+' '+record.servicePlannings.servicePlanningsPeriods.coming_hour,"YYYY-MM-DD HH:mm:ss"))).format("HH:mm") + ' hours' }}</small>
        </div>
      </div>

      <hr class="bg-primary">
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Ingeniero de Servicio:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.servicePlannings.users.name }}</small>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-6 text-right">
          <small><strong>Técnico de Laboratorio:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.client_plant }} </small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Técnico de Operaciones:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.collector }} </small>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-6 text-right">
          <small><strong>Tipo de Manejo:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ (record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials !== null) ? ((record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials[Object.keys(record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials)] !== null) ?
            ((record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials[Object.keys(record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials)].disposalTypes.name !== null ) ?
            record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials[Object.keys(record.servicePlannings.manifests[Object.keys(record.servicePlannings.manifests)].dischargeMaterials)].disposalTypes.name : '-') : '-' ) : '-' }}</small>
        </div>
      </div> -->
    </template>

    <template slot="carrier" slot-scope="record">
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Empresa Transportista:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>
            {{ record.carrier_company_name }}
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Operador:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ (record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].drivers.name !== null) ? record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].drivers.name : '-' }}</small>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Tipo de Vehículo:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ (record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].trucks.logisticsShipmentTypes !== null) ? record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].trucks.logisticsShipmentTypes.code + '-' + record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].trucks.logisticsShipmentTypes.name : '-'  }}</small>
        </div>
      </div>

      <div class="row">
        <div class="col-6 text-right">
          <small><strong>Placas:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ (record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].trucks !== null) ? record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].trucks.tag : '-' }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <small><strong>No. de Registro SCT:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ (record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].sct_record !== null) ? record.servicePlannings.servicePlanningsCarriers[Object.keys(record.servicePlannings.servicePlanningsCarriers)].sct_record : '-'  }} </small>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-6 text-right">
          <small><strong>No. Autorización SEMARNAT:</strong></small>
        </div>
        <div class="col-6 text-left">
          <small>{{ record.client_plant }} </small>
        </div>
      </div> -->
    </template>

    <template slot="action" slot-scope="data">
      <template v-if="typeOfAction === 'waste' ">
        <b-row>
          <b-col sm="12" :lg="(data.status_sap_data !== 'Pendiente' || data.status_sap_data !== 'Tratado por SAP') ? 12 : 6">
            <responsive-button class="mt-2 bg-real-blue"
                               size="sm"
                               pill block
                               responsive="md"
                               icon="calculator"
                               text="Contabilización Manual"
                               @ClickEvent="setManualAccounting(data)"
            />
          </b-col>
          <b-col sm="12" lg="6">
            <responsive-button class="mt-2"
                               size="sm"
                               pill block
                               responsive="md"
                               variant="warning"
                               icon="arrow-repeat"
                               text="Cambiar Contabilización"
                               @ClickEvent="ChangeAccountingType('modified', data)"
                               v-show="(data.status_sap_data === 'Pendiente' || data.status_sap_data === 'Tratado por SAP')"
            />
          </b-col>
          <b-col sm="12" lg="6">
            <responsive-button class="mt-2"
                               size="sm"
                               pill block
                               responsive="md"
                               variant="success"
                               text="Añadir a Batch"
                               icon="check-circle-fill"
                               @ClickEvent="SendBatchMaterial(data)"
                               v-show="(data.status_sap_data === 'Pendiente' || data.status_sap_data === 'Tratado por SAP')"
            />
          </b-col>
          <b-col sm="12" lg="6">
            <responsive-button class="mt-2"
                               pill block
                               size="sm"
                               responsive="md"
                               variant="danger"
                               icon="trash-fill"
                               text="Cancelar envío a SAP"
                               @ClickEvent="NotSendMaterial(data)"
                               v-show="(data.status_sap_data === 'Pendiente' || data.status_sap_data === 'Tratado por SAP')"
            />
          </b-col>
          <b-col sm="12" lg="6">
            <responsive-button class="mt-2"
                               size="sm"
                               pill block
                               responsive="md"
                               variant="danger"
                               icon="x-circle-fill"
                               text="Anular Cálculo"
                               @ClickEvent="CancelAccountingRecord(data)"
                               v-show="data.status_sap_data === 'Pendiente'"
            />
          </b-col>
          <b-col sm="12" lg="6">
            <responsive-button class="mt-2"
                               size="sm"
                               pill block
                               responsive="md"
                               variant="danger"
                               icon="trash-fill"
                               text="Anular Batch"
                               @ClickEvent="DeleteBatchMaterial(data)"
                               v-show="(data.status_sap_data === 'En Batch')"
            />
          </b-col>
        </b-row>
      </template>
      <template v-if="typeOfAction === 'accounting_waste' ">
        <responsive-button text="" icon="dash" variant="warning" pill size="sm" responsive="md"/>
      </template>
    </template>
    <!-- Columnas Personalizadas -->
    <!-- Columna Expandible -->
    <a-table
      slot="expandedRowRender"
      slot-scope="record"
      :columns="innerColumns"
      :data-source="normalizeInnerData(record.sapDataMaterials)"
      :pagination="false"
    >
      <template slot="innerDetail" slot-scope="data">
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Peligrosidad: </strong></small>
          </div>
          <div class="col-6 text-left">
            <strong v-show="data.dangerous_type_name === 'Manejo Especial'" class="text-success">
              {{ data.dangerous_type_name }}
            </strong>
            <strong v-show="data.dangerous_type_name === 'Residuo Peligroso'" class="text-danger">
              {{ data.dangerous_type_name }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Clave SAP del Residuo: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.sap_material }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Nombre de SAP:</strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.sap_manifest_material }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Precio Unitario ($): </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.unit_price }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>POS: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.pos }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>TPOS: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.tpos }}</small>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-6 text-right">
            <small><strong>Clave SEMARNAT: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{  }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>CRETIB: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{  }}</small>
          </div>
        </div> -->
        <hr class="bg-primary">
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Residuo Manifestado: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.material }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Corriente: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.current }}</small>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>No. de Contenedores: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.containers_manifest }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Tipo de Contenedor: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ data.container_type_manifest }}</small>
          </div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="col-6 text-right">-->
        <!--            <small><strong>Cantidad Manifestada: </strong></small>-->
        <!--          </div>-->
        <!--          <div class="col-6 text-left">-->
        <!--            <small>{{ data.manifest_quantity }}</small>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="row">-->
        <!--          <div class="col-6 text-right">-->
        <!--            <small><strong>UM: </strong></small>-->
        <!--          </div>-->
        <!--          <div class="col-6 text-left">-->
        <!--            <small>{{ data.um_manifest }}</small>-->
        <!--          </div>-->
        <!--        </div>-->

      </template>
      <template slot="innerType" slot-scope="data">
        {{ data.container_type_real }} | {{ data.containers_real }}
      </template>
      <template slot="manifestedContainers" slot-scope="data">
        {{ data.container_type_manifest }} | {{ data.containers_manifest }}
      </template>
      <template slot="innerWeight" slot-scope="data">
        {{ parseFloat(data.weight_on_parts).toFixed(4) }} | TON
      </template>
      <template slot="innerWeightManifest" slot-scope="data">
        {{ parseFloat(data.manifest_quantity).toFixed(4) }} | {{ data.um_manifest }}
      </template>
      <!-- <template slot="innerQuantityInvoice" slot-scope="data">
        {{ parseFloat(data.billing_quantity).toFixed(4) }} | {{ data.um_billing }}
      </template> -->
      <template slot="difference" slot-scope="data">
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Peso: </strong></small>
          </div>
          <!-- <div class="col-6 text-left">
            <small>{{ (data.um_manifest === "TON") ? (parseFloat(data.manifest_quantity).toFixed(4) - parseFloat(data.weight_on_parts).toFixed(4)) : (parseFloat(data.manifest_quantity).toFixed(4)/1000 - parseFloat(data.weight_on_parts).toFixed(4)) }}</small>
          </div> -->
          <div class="col-6 text-left">
            <small>{{ data.diff_percentage }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-right">
            <small><strong>Contenedores: </strong></small>
          </div>
          <div class="col-6 text-left">
            <small>{{ parseFloat(parseFloat(data.containers_manifest).toFixed(4) - parseFloat(data.containers_real).toFixed(4)).toFixed(1) }}</small>
          </div>
        </div>

      </template>
      <!-- <template slot="action" slot-scope="data">
        <responsive-button class="my-3"
                           text="Modificar Registro"
                           icon="pencil-square"
                           variant="secondary"
                           pill size="sm"
                           responsive="md"
                           @ClickEvent="ChangeSapDataMaterial('modified', data)"/>
      </template> -->
    </a-table>
    <!-- Columna Expandible -->
  </a-table>
</template>
<script>

export default {
  name: 'wasteAccountingServiceTableReport',
  props: {
    tableObject: {
      type: [Object],
      required: true,
    },
    showActions: {
      default: false,
      type: [Boolean],
      required: false,
    },
    typeOfAction: {
      default: 'waste',
      type: [String],
      required: false,
      validator: (value) => {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['waste', 'accounting_waste'].indexOf(value) !== -1
      },
    },
    isForPurchase: {
      default: false,
      type: [Boolean],
      required: false,
    },
    // en compras el contrato pasa a ser pedido
    // y el cliente pasa a ser el provedor
  },
  data() {
    return {
      resource: 'manifests',
      perPageValue: 15,
      currentPageValue: 1,
      // *******************************************
      // *******************************************
      // MAIN RECORD
      columns: [
        {
          title: 'Descripción',
          dataIndex: '',
          class: 'text-center',
          key: 'description',
          width: '100px',
          scopedSlots: {
            customRender: 'description',
          },
        },
        {
          title: 'Detalles',
          dataIndex: '',
          class: 'text-center',
          key: 'details',
          width: '100px',
          scopedSlots: {
            customRender: 'details',
          },
        },
        {
          title: 'Datos del Servicio',
          dataIndex: '',
          class: 'text-center',
          key: 'services',
          width: '100px',
          scopedSlots: {
            customRender: 'services',
          },
        },
        {
          title: 'Empresa Transportista',
          dataIndex: '',
          class: 'text-center',
          key: 'carrier',
          width: '100px',
          scopedSlots: {
            customRender: 'carrier',
          },
        },
        // {
        //   title: 'Acciones',
        //   dataIndex: '',
        //   key: 'x',
        //   width: '100px',
        //   align: 'center',
        //   scopedSlots: {
        //     customRender: 'action',
        //   },
        // },
      ],
      // MAIN RECORD
      // *******************************************
      // *******************************************

      // *******************************************
      // *******************************************
      // SECOND RECORD
      innerColumns: [
        {
          title: 'Detalles',
          dataIndex: '',
          key: 'innerDetail',
          class: 'small text-center font-weight-bold',
          scopedSlots: {
            customRender: 'innerDetail',
          },
        },
        {
          title: 'Tipo | No. Contenedores Reales',
          dataIndex: '',
          key: 'innerType',
          class: 'small text-center font-weight-bold',
          scopedSlots: {
            customRender: 'innerType',
          },
        },
        {
          title: 'Tipo | No. Contenedores Manifestados',
          dataIndex: '',
          key: 'manifestedContainers',
          class: 'small text-center font-weight-bold',
          scopedSlots: {
            customRender: 'manifestedContainers',
          },
        },
        {
          title: 'Cantidad Real | UM',
          dataIndex: '',
          key: 'innerWeight',
          class: 'small text-center font-weight-bold',
          scopedSlots: {
            customRender: 'innerWeight',
          },
        },
        {
          title: 'Cantidad Manifiestada | UM',
          dataIndex: '',
          key: 'innerWeightManifest',
          class: 'small text-center font-weight-bold',
          scopedSlots: {
            customRender: 'innerWeightManifest',
          },
        },
        // {
        //   title: 'Cantidad Facturación | UM',
        //   dataIndex: '',
        //   key: 'innerQuantityInvoice',
        //   class: 'small text-center font-weight-bold',
        //   scopedSlots: {
        //     customRender: 'innerQuantityInvoice',
        //   },
        // },
        {
          title: 'Diferencia',
          dataIndex: '',
          key: 'difference',
          class: 'small text-center font-weight-bold',
          scopedSlots: {
            customRender: 'difference',
          },
        },
        // {
        //   title: 'Acciones',
        //   dataIndex: '',
        //   key: 'action',
        //   class: 'small text-center font-weight-bold',
        //   scopedSlots: { customRender: 'action' },
        // },
      ],
      // SECOND RECORD
      // *******************************************
      // *******************************************
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
    }
  },
  methods: {
    // *******************************************
    // *******************************************
    // MANUAL ACCOUNTING FUNCTIONALITY
    setManualAccounting(data = {}) {
      this.$emit('setManualAccounting', data)
    },
    // MANUAL ACCOUNTING FUNCTIONALITY
    // *******************************************
    // *******************************************

    // *******************************************
    // *******************************************
    // FUNCTIONALITY TO SEND DATA TO BATCH
    DeleteBatchMaterial(data = {}) {
      this.$emit('DeleteBatch', data)
    },
    SendBatchMaterial(data = {}) {
      this.$emit('AddBatch', data)
    },
    NotSendMaterial(data = {}) {
      console.log('NotSendMaterial')
      this.$emit('NotSendSap', data)
    },
    // FUNCTIONALITY TO SEND DATA TO BATCH
    // *******************************************
    // *******************************************

    // *******************************************
    // *******************************************
    // ACCOUNTING TYPE MODAL
    ChangeAccountingType(action, data = {}) {
      this.$emit('ShowATModal', action, data)
    },
    CancelAccountingRecord(data = {}) {
      this.$emit('CancelAccounting', data)
    },
    // ACCOUNTING TYPE MODAL
    // *******************************************
    // *******************************************
    // *******************************************
    // *******************************************
    // SAP DATA MATERIAL MODAL
    ChangeSapDataMaterial(action, data = {}) {
      this.$emit('ShowSDMModal', action, data)
    },
    // SAP DATA MATERIAL MODAL
    // *******************************************
    // *******************************************
    // *******************************************
    // *******************************************
    // SAP MATERIAL
    ChangeSapMaterial(action, data = {}) {
      this.$emit('ShowDMModal', action, data)
    },
    // SAP MATERIAL
    // *******************************************
    // *******************************************
    handleSearch(selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
    normalizeInnerData(objectOfObjects) {
      const array = Object.keys(objectOfObjects).map(key => {
        return Object.assign(objectOfObjects[key])
      })
      return array
    },
  },
}
</script>

<style scoped>

</style>
