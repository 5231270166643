<template>
  <b-modal size="xl"
           title="Facturación de Servicio"
           v-model="showModal"
           @hide="CloseModal"
  >
    <b-container fluid>
      <a-form-model layout="horizontal"
                    :model="billingForm"
                    :rules="billingModalRules"
                    ref="billing-form"
      >
        <a-row>
          <a-col :sm="{ span: 12, offset: 0 }" :lg="{ span: 3, offset: 18 }">
            <strong>
              Servicio:
            </strong>
          </a-col>
          <a-col :sm="{ span: 12, offset: 0 }" :lg="{ span: 3, offset: 0 }" class="text-primary font-weight-bold">
            {{ serviceData.serviceCode }}
          </a-col>
        </a-row>
        <hr class="bg-primary">
        <a-row>
          <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :xl="{ span: 8 }">
            <a-form-model-item label="Número de Factura:" ref="billing_code" prop="billing_code">
              <a-input class="block" placeholder="Número de Factura" v-model="billingForm.billing_code"/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :xl="{ span: 8 }">
            <a-form-model-item label="Estatus de Facturación:" class="mx-1" ref="billing_status_name" prop="billing_status_name">
              <a-select v-model="billingForm.billing_status_name"
                        placeholder="Seleccionar"
              >
                <a-select-option v-for="option in billingStatusOptions"
                                 :key="option"
                                 :value="option"
                >
                  {{option}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :xl="{ span: 8 }">
            <a-form-model-item label="Fecha de facturación:" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="billingForm.billing_dated_at" style="width: 100%"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-show="!billingForm.load">
          <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 8, offset: 8 }" class="text-center">
            <responsive-button block variant="primary"
                               pill
                               size="md"
                               text="Guardar"
                               responsive="md"
                               icon="archive-fill"
                               @ClickEvent="OnSubmitBilling"
                               class="mr-2"
            />
          </a-col>
        </a-row>
        <my-spinner :load="billingForm.load"></my-spinner>
      </a-form-model>
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
</template>

<script>
import { AccountingMixin } from '@/mixins/core/AccountingMixin'
import moment from 'moment'
export default {
  name: 'CloseBillingModal',
  mixins: [AccountingMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    serviceData: {
      type: [Object],
      required: true,
    },
  },
  methods: {
    CloseModal() {
      this.$emit('CloseBillingModal')
    },
    OnSubmitBilling() {
      this.$refs['billing-form'].validate(valid => {
        if (valid) {
          this.InitializeModal(this.serviceData)
          this.$emit('FinishBillingProcess', this.serviceData, this.billingForm)
        } else {
          return false
        }
      })
    },
    InitializeModal(initialData) {
      this.billingForm.id = initialData.billingData.id
      this.billingForm.billing_dated_at = moment(this.billingForm.billing_dated_at).format('YYYY-MM-DD')
      this.billingForm.load = true
    },
  },
}
</script>

<style scoped>

</style>
